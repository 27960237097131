import Vue from 'vue'
import App from './App.vue'
import router from './route/route'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

// 点击空白处弹窗不关闭
ElementUI.Dialog.props.closeOnClickModal.default = false;
Vue.use(ElementUI);
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

router.afterEach((to, from) => {
  document.title = '嘉凌酱酒业-' + to.meta.title;
})