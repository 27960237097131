<template>
  <div id="app">
    <!-- 路由出口 -->
    <router-view></router-view>
    <!-- <Home/> -->
  </div>
</template>

<script>
export default {
}
</script>

<style lang="less">
@import './assets/reset.less';
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  height: 100%;
}
</style>
