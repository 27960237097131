import Vue from "vue";
import Router from "vue-router";
import Home from "@/view/Home";

Vue.use(Router);



export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "/home"
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/view/login/login"),
    },
    {
      path: "/home",
      component: Home,
      children: [
        {
          path: "/channelDay",
          name: "channelDay",
          component: () => import("@/view/channelCount/channelDay"),
          meta: {title:'渠道统计-天'}
        },
        {
          path: "/channelMonth",
          name: "channelMonth",
          component: () => import("@/view/channelCount/channelMonth"),
          meta: {title:'渠道统计-月'}
        },
        {
          path: "/kfDay",
          name: "kfDay",
          component: () => import("@/view/kfCount/kfDay"),
          meta: {title:'客户统计-天'}
        },
        {
          path: "/kfMonth",
          name: "kfMonth",
          component: () => import("@/view/kfCount/kfMonth"),
          meta: {title:'客户统计-月'}
        },
        {
          path: "/wkfDay",
          name: "wkfDay",
          component: () => import("@/view/kfCount/wkfDay"),
          meta: {title:'客户统计(天)'}
        },
        {
          path: "/wkfMonth",
          name: "wkfMonth",
          component: () => import("@/view/kfCount/wkfMonth"),
          meta: {title:'客户统计(月)'}
        },
        {
          path: "/kfDayAdmin",
          name: "kfDayAdmin",
          component: () => import("@/view/kfCount/kfDayAdmin"),
          meta: {title:'客户统计-天(主管)'}
        },
        {
          path: "/kfMonthAdmin",
          name: "kfMonthAdmin",
          component: () => import("@/view/kfCount/kfMonthAdmin"),
          meta: {title:'客户统计-月(主管)'}
        },

        {
          path: "/jd",
          name: "jd",
          component: () => import("@/view/jd/jd"),
          meta: {title:'获取客户资源'}
        },
        {
          path: "/kfCount",
          name: "kfCount",
          component: () => import("@/view/kfCount/kfCount"),
          meta: {title:'服务数据'}
        },
        {
          path: "/exportOrder",
          name: "exportOrder",
          component: () => import("@/view/exportOrder/exportOrder"),
          meta: {title:'订单导出'}
        },
        {
          path: "/index",
          name: "index",
          component: () => import("@/view/index/index"),
          meta: ['首页']

        },
        {
          path: "/kfVipOrder",
          name: "kfVipOrder",
          component: () => import("@/view/order/kfOrderVip"),
          meta: ['会员订单']
        },
        {
          path: "/adminList",
          name: "adminList",
          component: ()=> import("@/view/system/adminList"),
          meta: {title:'管理员列表'}
        },
        {
          path: "/kfAllCount",
          name: "kfAllCount",
          component: ()=> import("@/view/kfCount/kfAllCount"),
          meta: {title:'客服数据统计'}
        },
        {
          path: "/channelCount",
          name: "channelCount",
          component: ()=> import("@/view/channelCount/channelCount"),
          meta: {title:'渠道数据统计'}
        },
        {
          path: "/channelList",
          name: "channelList",
          component: ()=> import("@/view/channel/channelList"),
          meta: ['渠道管理', '渠道列表']
        },
        {
          path: "/orderVip",
          name: "orderVip",
          component: ()=> import("@/view/order/orderVip"),
          meta: ['渠道管理', '会员订单管理']
        },
        {
          path: "/channelStatistics",
          name: "channelStatistics",
          component: ()=> import("@/view/order/channelStatistics"),
          meta: ['渠道管理', '渠道列表']
        },
        {
          path: "/supplierSkuList",
          name: "supplierSkuList",
          component: ()=> import("@/view/supplier/supplierSkuList"),
          meta: ['供应商管理', '供应商品列表']
        },

        {
          path: "/supplierOrderList",
          name: "supplierOrderList",
          component: ()=> import("@/view/supplier/supplierOrderList"),
          meta: ['供应商管理', '供应商订单']
        },
        {
          path: "/phoneChargeOrderList",
          name: "phoneChargeOrderList",
          component: ()=> import("@/view/phone/phoneChargeOrderList"),
          meta: ['话费充值管理', '话费充值订单列表']
        },
        {
          path: "/vipGoodsrOrderList",
          name: "vipGoodsrOrderList",
          component: ()=> import("@/view/vipGoods/vipGoodsrOrderList"),
          meta: ['权益商品', "权益商品订单列表"]
        },
      ]
    },
  ],
});
