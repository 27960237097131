import axios from "axios";
import {Message} from "element-ui";
import router from '../route/route'

// export const baseURL = "http://192.168.0.104:20000";
export const baseURL = "https://jiuyeapi.zy-bk.cn";
const service = axios.create({
    // baseURL: "https://jk.xtq0.cn", // baseURL会自动加在请求地址上  下载链接需要
    baseURL: baseURL, // baseURL会自动加在请求地址上
    timeout: 3000,
});

export function get(api, data, success, error) {
    service.get(api,{
        params: data
    })
        .then(resp => {
            if (resp.data.code === 200){
                //直接获取data中的数据
                success(resp.data.data)
            }else {
                Message.error(resp.data.msg)
                error(resp.data)
            }
        })
        .catch(err => {
            Message.error(err)
        })
}

export function post(api, data, success, error) {
    service.post(api, data, {
        headers: {
            "Content-Type": 'application/json'
        }
    })
        .then(resp => {
            if (resp.data.code === 200){
                //直接获取data中的数据
                Message.success("操作成功")
                success(resp.data.data)
            }else {
                Message.error(resp.data.msg)
                error(resp.data)
            }
        })
        .catch(err => {
            Message.error(err)
        })
}


//添加请求拦截器
service.interceptors.request.use((config) => {
        // 在请求之前做些什么（获取对应设置token）
        config.headers["X-ACCESS-TOKEN"] = localStorage.getItem("token");
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

//添加响应拦截器
service.interceptors.response.use((response) => {
        //对响应的数据做些什么
        // let { status, message } = response.data;
        // if (status !== 200) {
        //     Message({ message: message || "error", type: "warning" });
        // }
    let code = response.data.code
    if (code === 1000){
        router.push({ path: "/login" });
    }
        return response;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default service;
