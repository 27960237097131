//  项目中我们大多数时候都会把对应的接口请求封装成api来调用
import {get, post} from './httpUtil';
// 登录接口
export function login(data,success){
    return post('/mall/admin/login',data,success)
}
//获取菜单
export function menu(success){
    return get('/mall/admin/menu',null,success)
}
//获取管理员
export function adminPage(data,success){
    return get('/mall/admin/page',data,success)
}
//编辑管理员
export function editAdmin(data,success){
    return post('/mall/admin/edit',data,success)
}
//获取角色列表
export function adminRoleList(success){
    return get('/mall/admin/role/list',null,success)
}

//获取投放平台列表
export function channelPlatformList(success){
    return get('/mall/admin/channel/platform/list',null,success)
}

//渠道列表
export function channelPage(data,success){
    return get('/mall/admin/channel/page',data,success)
}

//渠道路由列表
export function channelRouteList(data,success){
    return get('/mall/admin/channel/route/list',data,success)
}

//管理员列表
export function adminList(success){
    return get('/mall/admin/list',null,success)
}

//渠道列表
export function statisticsPage(data,success){
    return get('/mall/admin/channel/statistics/page',data,success)
}

//获取支付平台列表
export function payPlatformList(data,success){
    return get('/mall/admin/pay/platform/list',data,success)
}
//添加渠道
export function addChannel(data,success){
    return post('/mall/admin/channel/add',data,success)
}
//编辑渠道
export function editChannel(data,success){
    return post('/mall/admin/channel/edit',data,success)
}
//会员订单列表
export function vipOrderPage(data,success){
    return get('/mall/admin/vip/order/page',data,success)
}
//会员订单列表
export function kfOrderInfo(data,success){
    return get('/mall/admin/vip/order/kfOrderInfo',data,success)
}

//会员订单退款
export function vipOrderRefund(data,success){
    return post('/mall/admin/vip/order/refund',data,success)
}

//供应商品sku列表
export function supplierSkuPage(data,success){
    return get('/mall/admin/supplier/sku/page',data,success)
}

//供应商品sku添加
export function addSupplierSku(data,success){
    return post('/mall/admin/supplier/sku/add',data,success)
}
//供应商品sku编辑
export function editSupplierSku(data,success){
    return post('/mall/admin/supplier/sku/edit',data,success)
}

//供应商列表
export function supplierList(success){
    return get('/mall/admin/supplier/sku/supplier/list',null,success)
}

//供应商订单
export function supplierOrderPage(data,success){
    return get('/mall/admin/supplier/order/page',data,success)
}

//话费订单
export function phoneChargeOrderPage(data,success){
    return get('/mall/admin/phone/charge/order/page',data,success)
}

//权益充值订单
export function vipGoodsOrderPage(data,success){
    return get('/mall/admin/vip/goods/order/page',data,success)
}   

//回调操作
export function supplierSelfCallback(data,success){
    return post('/mall/admin/supplier/self/callback',data,success)
}


//获取订单
export function getJdOrder(success){
    return get('/mall/admin/order/jd',null,success)
}
//获取订单
export function getJdOrderTwo(success){
    return get('/mall/admin/order/jd/two',null,success)
}

//完成访问
export function finishOrder(data,success){
    return post('/mall/admin/order/finish',data,success)
}
//客服数据
export function kfCount(success){
    return get('/mall/admin/order/kf/count',null,success)
}
//客服获取订单数据
export function kfGetOrderInfo(data,success){
    return get('/mall/admin/order/kf/order/info',data,success)
}

//下载订单
export function exportOrder(data,success){
    return get('/mall/admin/order/export',data,success)
}
//下载订单记录
export function exportOrderList(success){
    return get('/mall/admin/order/export/list',null,success)
}
//获取下载excel凭证
export function exportOrderStk(data,success){
    return get('/mall/admin/order/export/stk',data,success)
}

//同步快递状态
export function pullExportStatus(success){
    return post('/mall/admin/order/pull/express/status',null,success)
}

//退出
export function logout(success){
    return post('/mall/admin/logout',null,success)
}
//客服数据
export function kfAllCount(data,success){
    return get('/mall/admin/order/kf/count/list',data,success)
}

//客服数据
export function channelCount(data,success){
    return get('/mall/admin/order/channel/count/list',data,success)
}


//数据统计
export function kfDay(data,success){
    return get('/mall/admin/statistics/kf/day',data,success)
}

export function kfMonth(data,success){
    return get('/mall/admin/statistics/kf/month',data,success)
}

export function channelDay(data,success){
    return get('/mall/admin/statistics/channel/day',data,success)
}

export function channelMonth(data,success){
    return get('/mall/admin/statistics/channel/month',data,success)
}

export function kfDayAdmin(data,success){
    return get('/mall/admin/statistics/kf/day/admin',data,success)
}

export function kfMonthAdmin(data,success){
    return get('/mall/admin/statistics/kf/month/admin',data,success)
}

export function adminEditPsw(data,success){
    return post('/mall/admin/edit/psw',data,success)
}