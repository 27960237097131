<template>
  <div class="home">
    <el-container class="main-container">
      <el-aside width="180px">
        <div class="title"><h5><img src="favicon.ico" height="160" width="160"></h5></div>
        <el-menu>
          <template v-for="(i) in menuList">
            <el-submenu v-if="!i.route" :key="i.id" :index="i.title">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span>{{ i.title }}</span>
              </template>
              <el-menu-item-group>
                <el-menu-item v-for="(j) in i.son" :key="j.id" :index="j.title" @click="goPage(j.route)">
                  <span>{{ j.title }}</span>
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <!--            只有一级菜单-->
            <el-menu-item v-else @click="goPage(i.route)">
              <span>{{ i.title }}</span>
            </el-menu-item>
          </template>
        </el-menu>
      </el-aside>

      <el-container>
        <el-header style="text-align: right; font-size: 20px;height: 60px">
          <div >
          <el-breadcrumb separator-class="el-icon-arrow-right" style="padding-top: 20px;font-size: 20px">
            <el-breadcrumb-item v-for="(item) in $route.meta" :key="item">{{item}}</el-breadcrumb-item>
          </el-breadcrumb>
          </div>
          <div>
            <span>您好！ : {{ username }}</span>
            <el-dropdown style="margin-left: 15px">
              <i class="el-icon-setting" style="margin-right: 15px;size: 40px"></i>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="editPsw()">修改密码</el-dropdown-item>
                <el-dropdown-item @click.native="logout()">退出</el-dropdown-item>

              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </el-header>
        <el-main>
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>


    <el-dialog title="修改密码(数字+字母6-18位)" :visible.sync="dialogFormVisible" width="20%">
      <el-form ref="form"  label-width="100px">
        <el-form-item label="请输入新密码">
          <el-input type="password" v-model="psw"></el-input>
        </el-form-item>
        <el-form-item label="请确认新密码">
          <el-input type="password" v-model="psw1"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="editPswDo()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {adminEditPsw, logout, menu} from "@/api/api";
import {Message} from "element-ui";
export default {
  data() {
    return {
      menuList: [],
      username: "",
      roleName: "",
      dialogFormVisible: false,
      psw:"",
      psw1:"",
    }
  },

  created() {
    this.username = localStorage.username
    this.roleName = localStorage.roleName
    this.getMenu()
  },
  methods: {

    editPsw(){
      this.dialogFormVisible = true
    },
    editPswDo(){
      //修改密码
      if (this.psw !== this.psw1){
        Message.error("两次输入密码不一致")
        return
      }
      if (this.psw.length < 6){
        Message.error("密码长度至少6位")
        return
      }
      if (this.psw.length > 18){
        Message.error("密码长度至多18位")
        return
      }

      let param = {
        psw: this.psw
      }
      adminEditPsw(param,success=>{
        this.logout();
      })
      //退出登录
    },
    getMenu() {
      menu(data => {
        this.menuList = data
        //打开第一个页面
        let one = data[0];
        let route = "";
        if (one.route) {
          //一级页面有路由直接跳
          route = one.route
        } else {
          //一级页面没有路由
          route = one.son[0].route
        }
        if (this.$router.currentRoute.fullPath === route) {
          return
        }
        if (this.$router.currentRoute.fullPath !=='/home'){
          return;
        }
        this.$router.push({path: route});
      })
    },
    logout(){
      logout(()=>{
        //清楚缓存
        localStorage.clear();
        this.$router.push({ path: "/login" });
      })

    },
    goPage(route) {
      if (this.$router.currentRoute.fullPath === route) {
        return
      }
      this.$router.push({path: route});
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.home {
  width: 100%;
  height: 100%;
  position: absolute;
}

.main-container {
  width: 100%;
  height: 100%;

  .el-aside {
    background-color: whitesmoke;
    font-size: 25px;
  }
}

.title {
  padding: 20px 10px;
}
</style>
